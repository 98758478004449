import { BannerSection } from 'components/BannerSection';
import ClientHeader from 'components/ClientHeader';
import { FAQs } from 'components/FAQs';
import { Footer } from 'components/Footer';
import { OurStory } from 'components/OurStory';
import { Services } from 'components/Services';
import dynamic from 'next/dynamic';
import React from 'react';

import { cx } from '@emotion/css';

import styles from './HomePage.module.scss';

const DynamicWhyChooseUs = dynamic(() => import('components/WhyChooseUs'), {
  ssr: true,
});
const DynamicReviews = dynamic(() => import('components/TrustPilot'), {
  ssr: true,
});
export const HomePage = () => {
  return (
    <div className={styles.body}>
      <div className={cx(styles.sec, styles.root, styles.root_home)}>
        <ClientHeader styles={styles} />
        <BannerSection styles={styles} />
        <DynamicReviews styles={styles} />
        <DynamicWhyChooseUs styles={styles} />
        <Services styles={styles} />
        <FAQs styles={styles} />
        <OurStory styles={styles} />
        <Footer styles={styles} />
      </div>
    </div>
  );
};
