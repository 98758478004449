import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useEffect, useState } from 'react';

import { TRUST_PILOT_LINK } from '@common/constants';
import { cx } from '@emotion/css';

const Lottie = dynamic(() => import('react-lottie-player'), {
  ssr: true,
});

const DynamicSearchComponent = dynamic(() => import('./SearchComponent'), {
  ssr: true,
});

interface LottieState {
  handyman?: any;
  family?: any;
}

export const BannerSection = ({ styles }: any) => {
  const [animationData, setAnimationData] = useState<LottieState>();
  useEffect(() => {
    import('public/lottie/handyman.json').then((res) =>
      setAnimationData((state) => ({ ...state, handyman: res.default })),
    );
    import('public/lottie/family.json').then((res) =>
      setAnimationData((state) => ({ ...state, family: res.default })),
    );
  }, []);

  return (
    <section className={cx(styles.sec, styles.banner_sec)}>
      <div className={styles.container_fluid}>
        <div className={cx(styles.row, styles.align_end, styles.spaceb)}>
          <div className={styles.left_blk}>
            <Lottie
              animationData={animationData?.handyman}
              className={styles.lottie_player}
              speed={1}
              loop
              play></Lottie>
          </div>
          <div className={cx(styles.center_blk, styles.text_center)}>
            <div className={styles.banner_content}>
              <h1 className={cx(styles.h1, styles.main_title)}>
                The smart way to{' '}
                <span className={styles.rb_g}>book maintenance.</span>
              </h1>
              <h6 className={styles.h6}>
                Top Tradespeople / Easy Booking / Transparent Communication
              </h6>
            </div>
            <DynamicSearchComponent styles={styles} />
            <Link
              href={TRUST_PILOT_LINK}
              className={styles.link}
              target="_blank">
              <a className={styles.link} target="_blank">
                <div className={styles.rating_sec}>
                  <img
                    src="images/icons/stars.svg"
                    alt=""
                    className={styles.img}
                  />
                  <p className={styles.p}>
                    Rated <b>excellent</b> on <b>Trustpilot</b>
                  </p>
                </div>
              </a>
            </Link>
          </div>
          <div className={styles.right_blk}>
            <Lottie
              animationData={animationData?.family}
              speed={1}
              loop
              style={{ maxWidth: '450px', margin: '0px auto' }}
              className={styles.lottie_player}
              play></Lottie>
          </div>
        </div>
      </div>
    </section>
  );
};
