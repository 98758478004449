import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

import { CLIENT_ROUTE, CONTACT } from '@common/constants';
// import { CONTACT } from '@common/constants';
import { cx } from '@emotion/css';
import { useWindowScrollPositions } from '@util/useWindowScrollPositions';

const ClientHeader = ({ styles }: any) => {
  const { scrollY } = useWindowScrollPositions();
  return (
    <header className={cx(styles.header, { [styles.fixed]: scrollY >= 20 })}>
      <div className={styles.container_fluid}>
        <div className={cx(styles.row, styles.center, styles.spaceb)}>
          <Link href="/" aria-label="Logo, Home page link">
            <a className={styles.logo_wrpr}>
              <Image
                src="/images/qeeper-logo.svg"
                alt="Qeeper Logo"
                height={60}
                width={200}
              />
            </a>
          </Link>
          <div className={styles.links_wrpr}>
            <Link href={`tel:${CONTACT.PHONE}`}>
              <a className={styles.phone_link}>{CONTACT.PHONE}</a>
            </Link>
            <Link href={`mailto:${CONTACT.EMAIL}`}>
              <a className={styles.btn}>Email us</a>
            </Link>
            <Link href={CLIENT_ROUTE.PROPERTY_MANAGERS}>
              <a className={styles.btn_accent}>Property Managers</a>
            </Link>
          </div>
        </div>
      </div>
      <div className={styles.backdrop_blur}></div>
    </header>
  );
};

export default ClientHeader;
