import React from 'react';

import { CONTACT } from '@common/constants';
import { cx } from '@emotion/css';

interface ICardProps {
  styles: any;
  title: string;
  details: any;
}
const FAQCard = ({ styles, title, details }: ICardProps) => (
  <div className={styles.faq_card}>
    <h4 className={cx(styles.h4, styles.faq_title)}>{title}</h4>
    <p className={cx(styles.p, styles.faq_desc)}>{details}</p>
  </div>
);
export const FAQs = ({ styles }: any) => {
  return (
    <section className={cx(styles.sec, styles.faq_sec)}>
      <div className={cx(styles.container, styles.inner)}>
        <div className={styles.row}>
          <div className={cx(styles.heading_blk, styles.w100)}>
            <h2 className={cx(styles.h2, styles.text_center)}>
              Frequently Asked Questions
            </h2>
          </div>
        </div>
        <div className={cx(styles.row, styles.spaceb, styles.faq_card_wrpr)}>
          <FAQCard
            styles={styles}
            title="Unsure what trade a job falls under?"
            details={
              <span>
                We know maintenance can be confusing. Our team is always happy
                to help via email <u>{CONTACT.EMAIL}</u>, or phone{' '}
                {CONTACT.PHONE}. Our office hours are 9am - 6pm 7 days a week.
              </span>
            }
          />
          <FAQCard
            styles={styles}
            title="What areas do you cover? "
            details={`At the moment, we are live in London, Brighton, and Edinburgh. We will have some exciting news about expanding to new markets soon!`}
          />
          <FAQCard
            styles={styles}
            title="I am a Tradesperson, how can I join?"
            details={
              <span>
                Drop us an email to <u>{CONTACT.TRADES_EMAIL}</u> and the Qeeper
                team we will be in touch. We are a friendly bunch with high
                standards.
              </span>
            }
          />
        </div>
        {/* <div className={styles.faq_all}>
          <a href="#" className={styles.link}>
            View all{' '}
            <span className={styles.link_arrow}>
              <img src="images/icons/arrow-red.svg" alt="" />
            </span>
          </a>
        </div> */}
      </div>
    </section>
  );
};
