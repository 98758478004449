import dynamic from 'next/dynamic';
import React, { useEffect, useState } from 'react';

import { cx } from '@emotion/css';

const Lottie = dynamic(() => import('react-lottie-player'), {
  ssr: true,
});
interface LottieState {
  story?: any;
}

export const OurStory = ({ styles }: any) => {
  const [animationData, setAnimationData] = useState<LottieState>();
  useEffect(() => {
    import('public/lottie/our-story.json').then((res) =>
      setAnimationData((state) => ({ ...state, story: res.default })),
    );
  }, []);
  return (
    <section className={cx(styles.sec, styles.story_sec)}>
      <div className={cx(styles.container, styles.inner)}>
        <div className={styles.row}>
          <div className={cx(styles.our_story_block, styles.text_center)}>
            <div className={cx(styles.heading_blk, styles.w100)}>
              <h2 className={cx(styles.h2, styles.text_center)}>
                Why on earth did we get into maintenance?
              </h2>
            </div>
            <h6 className={cx(styles.h6, styles.our_stody_edsc)}>
              It’s not because we love maintenance, that's for sure. The boiler
              breaking down on a cold January day is not fun for anyone. We feel
              the current system does not benefit customers or tradespeople.
              Organising a repair is often slow, exhausting, and complicated. We
              feel anyone should have easy access to their area’s best
              Tradespeople, fast.
            </h6>
            {/* <div className={styles.ourstory_link}>
              <a href="#" className={styles.link}>
                Read our story{' '}
                <span className={styles.link_arrow}>
                  <img src="images/icons/arrow-red.svg" alt="" />
                </span>
              </a>
            </div> */}
            <div className={styles.our_story_img}>
              <Lottie
                animationData={animationData?.story}
                speed={1}
                loop
                style={{ maxWidth: '450px', margin: '0px auto' }}
                play></Lottie>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
