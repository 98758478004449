import { NextSeo } from 'next-seo';
import { Html } from 'next/document';
import Head from 'next/head';
import React from 'react';

import { WEB_LINK } from '@common/constants';
import { HomePage } from '@modules/home/HomePage';

const Home = () => {
  return (
    <>
      <Head>
        {/* PWA primary color */}
        <link
          href="https://fonts.googleapis.com/css2?family=Arimo:wght@400;700&family=Plus+Jakarta+Sans:wght@200;400;500;600;700;800&display=optional"
          rel="stylesheet"
        />
      </Head>
      <NextSeo
        title="Qeeper - Home"
        description="On-demand property maintenance across London, Edinburgh and Brighton"
        canonical={WEB_LINK}
        openGraph={{
          url: WEB_LINK,
          title: 'Qeeper - Home',
          description:
            'On-demand property maintenance across London, Edinburgh and Brighton',
        }}
      />
      <HomePage />
    </>
  );
};

Home.getLayout = (page: any) => <Html lang="en">{page}</Html>;

export default Home;
